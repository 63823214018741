<template>
  <div class="page-header-index-wide page-header-wrapper-grid-content-main">
    <a-row :gutter="24">
      <a-col :md="24" :lg="7">
        <a-card :bordered="false">
          <div class="account-center-avatarHolder">
            <div class="avatar" v-if="dingUser.avatar && dingUser.avatar !== ''">
              <img :src="dingUser.avatar">
            </div>
            <div v-else class="avatar avatarBody">
              <span class="name">{{ dingUser.name }}</span>
            </div>
            <div class="username">{{ dingUser.name }}</div>
            <div class="bio">{{ dingUser.department ? (dingUser.department ? dingUser.department.name : '--') : '--' }}</div>
          </div>
          <!-- <div class="account-center-detail">
            <p>
              <i class="title"></i>{{ dingUser.title }}
            </p>
            <p>
              <span v-if="dingUser.admin">
                <i class="group"></i>管理员
              </span>
              <span v-else>
                <i class="group"></i>成员
              </span>
              
            </p>
            <p>
              <i class="address"></i>
              <span>{{ dingUser.workPlace }}</span>
            </p>
          </div> -->
          
          <!-- <a-divider/> -->
          <!-- <div class="account-center-team">
            <div class="teamTitle">身份</div>
            <div class="members">
              <a-row>
                <a-col :span="12" v-for="(item, index) in dingUser.roleList" :key="index">
                  <a>
                    <span class="member">{{ item.groupName }} - {{ item.name }}</span>
                  </a>
                </a-col>
              </a-row>
            </div>
          </div> -->

          <a-divider />
          <a-button type="primary" block @click="reBind">重新绑定</a-button>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {}
  },
  computed: {
    dingUser() {
      return this.$store.state.dingUser.data
    }
  },
  mounted () {},
  methods: {
    reBind() {
      this.$emit('reBind')
    }
  }
}
</script>

<style lang="less" scoped>
.avatarBody {
  background: #1890ff;
  border-radius: 50%;
  position: relative;
  .name {
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    color: #fff;
  }
}
.page-header-wrapper-grid-content-main {
  width: 100%;
  height: 100%;
  min-height: 100%;
  transition: 0.3s;

  .account-center-avatarHolder {
    text-align: center;
    margin-bottom: 24px;
    & > .avatar {
      margin: 0 auto;
      width: 104px;
      height: 104px;
      margin-bottom: 20px;
      border-radius: 50%;
      overflow: hidden;
      img {
        height: 100%;
        width: 100%;
      }
    }

    .username {
      color: rgba(0, 0, 0, 0.85);
      font-size: 20px;
      line-height: 28px;
      font-weight: 500;
      margin-bottom: 4px;
    }
  }

  .account-center-detail {
    p {
      margin-bottom: 8px;
      padding-left: 26px;
      position: relative;
    }

    i {
      position: absolute;
      height: 14px;
      width: 14px;
      left: 0;
      top: 4px;
      background: url(https://gw.alipayobjects.com/zos/rmsportal/pBjWzVAHnOOtAUvZmZfy.svg);
    }

    .title {
      background-position: 0 0;
    }
    .group {
      background-position: 0 -22px;
    }
    .address {
      background-position: 0 -44px;
    }
  }

  .account-center-tags {
    .ant-tag {
      margin-bottom: 8px;
    }
  }

  .account-center-team {
    .members {
      a {
        display: block;
        margin: 12px 0;
        line-height: 24px;
        height: 24px;
        .member {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.65);
          line-height: 24px;
          max-width: 100px;
          vertical-align: top;
          margin-left: 12px;
          transition: all 0.3s;
          display: inline-block;
        }
        &:hover {
          span {
            color: #1890ff;
          }
        }
      }
    }
  }

  .tagsTitle,
  .teamTitle {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 12px;
  }
}
</style>
