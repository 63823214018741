<template>
  <a-spin :spinning="loading">
    <div v-show="visible" class="login-container">
      <div id="login_container" class="dingding_scan"></div>
    </div>
    <ding-info v-show="!visible" @reBind="reBind" />
  </a-spin>
</template>

<script>
// import { ddlogin } from "@/api/dashboard";
import { moxiRelation } from '@/api/moxi'
import DingInfo from './components/DingInfo'
const DDLogin = window.DDLogin

export default {
  name: 'Login',
  components: {
    DingInfo
  },
  data() {
    return {
      visible: true,
      loading: false
    }
  },
  computed: {
    userId() {
      return this.$store.state.user.info.id
    },
    dingUser() {
      console.log(this.$store.state.dingUser.data, 'register');
      return this.$store.state.dingUser.data
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },
  created() {
  },
  mounted() {
    this.loading = true
    this.loginValidator()
    this.ddLogin()
  },
  destroyed() {},
  methods: {
    loginValidator() {
      moxiRelation(this.userId).then(res => {
        if (res.success) {
          const data = res.data
          this.$store.commit('SET_DINGUSER', data)
          this.visible = false
        } else {
          this.visible = true
        }
      }).finally(() => {
        this.loading = false
      })
    },
    reBind() {
      this.visible = true
      this.ddLogin()
    },
    ddLogin() {
      // 默认二维码的显示
      const appid = 'dingoaitxsc0qyuc4yolet'
      const strurl = 'https://wzg.jinlutech.cn/api/v1/dingtalk/ding_talk/callback/user_info/'
      // const strurl = 'http://w3826759p1.zicp.vip/v1/dingtalk/callback/userinfo'
      const url = encodeURIComponent(strurl)

      const param = {
        appid,
        response_type: 'code',
        scope: 'snsapi_login',
        state: this.userId,
        redirect_uri: url
      }
      const href = this.requestFormat('https://oapi.dingtalk.com/connect/qrconnect', param)
      const goto = encodeURIComponent(href)
      console.log(href, 'href---------------')
      DDLogin({
        id: 'login_container',
        goto: goto,
        style: 'border:none;background-color:#FFFFFF;',
        width: '290',
        height: '300'
      })
      const that = this
      var hanndleMessage = function(event) {
        var origin = event.origin
        // console.log("origin", event.origin);
        // 判断是否来自ddLogin扫码事件。
        if (origin === 'https://login.dingtalk.com') {
          var loginTmpCode = event.data // 拿到loginTmpCode后就可以在这里构造跳转链接进行跳转了
          console.log('loginTmpCode', loginTmpCode);
          if (loginTmpCode) {
            const skip = {
              appid,
              response_type: 'code',
              scope: 'snsapi_login',
              state: that.userId,
              redirect_uri: url
            }
            Object.assign(skip, { loginTmpCode })
            window.location.href = that.requestFormat('https://oapi.dingtalk.com/connect/oauth2/sns_authorize', skip)
            // 拿到用户唯一标示然后在进行当前页面的调转，注意这里跳转以后还是会在当前页面只不过Url上带了参数了这时候咱们去看上面的条件
          }
        }
      }
      if (typeof window.addEventListener !== 'undefined') {
        window.addEventListener('message', hanndleMessage, false)
      } else if (typeof window.attachEvent !== 'undefined') {
        window.attachEvent('onmessage', hanndleMessage)
      }
    }
  }
}
</script>

<style lang="less" scoped>
// $bg: #2d3a4b;
// $dark_gray: #889aa4;
// $light_gray: #eee;

.login-container {
  min-height: 100%;
  width: 100%;
  // background: url('https://ste.oss-cn-chengdu.aliyuncs.com/mediaDesign/bgTuying.png?x-oss-process=image/resize,w_2560');
  background: #bae7ff url(https://ste.oss-cn-chengdu.aliyuncs.com/mediaDesign/memberGg.png?x-oss-process=image/resize,w_2560) no-repeat bottom;
  overflow: hidden;
  .dingding_scan {
    margin-top: 10%;
    float: right;
    width: 500px;
    height: 500px;
  }
  /deep/ iframe {
    width: 290px;
    height: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
